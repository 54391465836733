import { LOADING_OPTIONS } from '@components';
import { COMPATIBLE_DEVICES } from '@shared/constants/pages/product-page-common';
import { APPS_LINKS } from '@shared/constants/apps';
import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { trackCustomGA4Event } from '@shared/google-utils';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps images
import DownloadInstall from '@images/product-page/download-install.svg';
import ConnectEnjoy from '@images/product-page/connect-enjoy.svg';
import UpgradeAnytime from '@images/product-page/upgrade-anytime.svg';
import AppStore from '@images/app-store-apple.svg';
// Perks images
import GlobalContent from '@images/product-page/global-content.svg';
import SupportBeyondVpn from '@images/product-page/support-beyond-vpn.svg';
import UnlimitedAccess from '@images/product-page/unlimited-access.svg';
import HassleFree from '@images/product-page/hassle-free.svg';

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('vpnForIphone:metaData.title'),
    description: t('vpnForIphone:metaData.description'),
    image: META_IMAGE,
    keywords: [
      'free VPN for iOS',
      'VPN for iPhone',
      'iOS VPN for iPhone and iPad',
      'VPN for iPad',
      'iPhone 12',
      'iPhone 11',
      'iPhone SE',
      'iPhone X',
      'VPN on iPhone, iOS VPN app.',
    ],
  }),
  headerCta: {
    text: ({ isLoading, discountPercentage, pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForIphone:headerCta.title'),
      subtitle: t('vpnForIphone:headerCta.subtitle'),
      description: [
        { title: t('vpnForIphone:headerCta.productFeatures.unlimitedBandwidth') },
        { title: t('vpnForIphone:headerCta.productFeatures.easyAccess') },
        { title: t('vpnForIphone:headerCta.productFeatures.bulletproofProtection') },
        { title: t('vpnForIphone:headerCta.productFeatures.adFree') },
      ],
      buttons: [
        {
          title: t('common:actions.downloadFree'),
          link: APPS_LINKS.ios,
          buttonProps: {
            variant: 'outlined',
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.iOS },
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'product-page/ios-header-image-mobile.png'),
        alt: 'iOS page header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'product-page/ios-header-image-tablet.png'),
        alt: 'iOS page header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'product-page/ios-header-image.png'),
        alt: 'iOS page header',
        loading: LOADING_OPTIONS.eager,
        sizes: '2842px',
        position: {
          top: -80,
          height: 746,
          maxWidth: 2842,
        },
      },
    }),
  },
  compatibleDevices: (t) => ({ cards: COMPATIBLE_DEVICES(t), mobileCards: COMPATIBLE_DEVICES(t) }),
  steps: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForIphone:stepCards.title'),
    description: t('vpnForIphone:stepCards.description'),
    items: [
      {
        image: DownloadInstall,
        step: 1,
        title: t('vpnForIphone:stepCards.downloadInstall.title'),
        description: t('vpnForIphone:stepCards.downloadInstall.description'),
        button: {
          image: { src: AppStore, alt: 'Get it on App Store' },
          handleOnClick: () => {
            window.open(APPS_LINKS.ios, '_blank').focus();
            trackCustomGA4Event({ eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.iOS });
          },
        },
      },
      {
        image: ConnectEnjoy,
        step: 2,
        title: t('vpnForIphone:stepCards.connectEnjoy.title'),
        description: t('vpnForIphone:stepCards.connectEnjoy.description'),
      },
      {
        image: UpgradeAnytime,
        step: 3,
        title: t('vpnForIphone:stepCards.upgradeAnytime.title'),
        description: t('vpnForIphone:stepCards.upgradeAnytime.description', {
          serverCount: SERVER_COUNT,
        }),
      },
    ],
  }),
  digitalLife: (t) => ({
    title: t('vpnForIphone:reclaimControl'),
    description: t('vpnForIphone:joinUs'),
    data: [
      {
        isReversed: true,
        image: getFetchedImage(images, 'product-page/safety-for-activities.png'),
        title: t('vpnForIphone:contentBlocks.safety.title'),
        description: t('vpnForIphone:contentBlocks.safety.description'),
        link: {
          title: t('common:links.waysToUseVpn'),
          link: formatLink(t('navigation:paths.use-cases')),
          customEvent: { eventName: GA4_EVENTS.waysToUseVpnClick },
        },
      },
    ],
  }),
  perksBefore: (t) => ({
    items: [
      {
        image: GlobalContent,
        title: t('vpnForIphone:perkCards.globalContent.title'),
        description: t('vpnForIphone:perkCards.globalContent.description'),
      },
      {
        image: SupportBeyondVpn,
        title: t('vpnForIphone:perkCards.supportBeyondVpn.title'),
        description: t('vpnForIphone:perkCards.supportBeyondVpn.description'),
      },
    ],
  }),
  highSpeed: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('vpnForIphone:contentBlocks.speed.title'),
        description: t('vpnForIphone:contentBlocks.speed.description'),
      },
    ],
  }),
  perksAfter: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    items: [
      {
        image: UnlimitedAccess,
        title: t('vpnForIphone:perkCards.noLimits.title'),
        description: t('vpnForIphone:perkCards.noLimits.description'),
      },
      {
        image: HassleFree,
        title: t('vpnForIphone:perkCards.easeOfUse.title'),
        description: t('vpnForIphone:perkCards.easeOfUse.description'),
      },
    ],
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
  }),
  reviews: (t) => ({
    title: t('vpnForIphone:reviews.title'),
  }),
  moneyBackGuarantee: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('common:moneyBackGuarantee14.title'),
        description: t('common:moneyBackGuarantee14.description'),
      },
    ],
  }),
  pricing: ({ t }) => ({
    title: t('vpnForIphone:pricingSection.title'),
    description: t('vpnForIphone:pricingSection.description'),
    footerProps: {
      text: t('vpnForIphone:pricingSection.notReadyYet'),
      button: {
        link: APPS_LINKS.ios,
        title: t('common:actions.download'),
        buttonProps: { customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.iOS } },
      },
    },
  }),
  faq: ({ locationCount }) => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'Can you use Atlas VPN for free?',
        content:
          'Yes, you can use our free VPN for the iPhone version. It features multiple free servers that you can connect to without paying a cent. You can upgrade to premium at any point to increase the number of available servers, enjoy additional features, and boost performance.',
      },
      {
        header: 'Why should you upgrade to premium?',
        listLabel: 'Premium services bring:',
        listItems: [
          'Video-optimized servers',
          `More than ${SERVER_COUNT} servers in ${locationCount} locations worldwide`,
          '24/7 customer support',
          'Simultaneous premium services usage on an unlimited number of devices',
          'Privacy Pro servers for more security',
          'Access to Android TV and Amazon Fire TV apps',
          'No data limit and better speed',
        ],
      },
      {
        header: 'Is it necessary to use a VPN on iOS?',
        content:
          'Yes, you should use a VPN on iOS. Users typically use their mobile devices for important tasks, such as checking their bank accounts, shopping, or communicating with friends. All these activities need protection. A VPN helps secure all these actions and prevents entities from invading your privacy. Thus, you do not have to worry about your information being leaked, stolen, or tracked.',
      },
      {
        header: 'Does a VPN protect you on unsecured Wi-Fi networks',
        content:
          'Yes, definitely! Many free public Wi-Fi options do not offer the best security setup. Instead, they might leave your data unencrypted and make it easy for other users to spy on your actions. A VPN resolves these concerns by encrypting all of your traffic, meaning that all your data travels safely online.',
      },
      {
        header: 'Can you use Atlas VPN on multiple devices?',
        content:
          'There is no limit to the number of devices you can choose to protect with Atlas VPN. You can use it on your Android, iOS, macOS, Windows, Android TV, and Amazon Fire TV devices simultaneously. It is a great family-style deal, too! Help your loved ones stay safe online and protect your family’s private information, financial transactions, and digital identities. Sharing is caring, and Atlas VPN strongly believes in this principle.',
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForIphone:footerCta.title'),
      description: t('vpnForIphone:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'iOS page footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'iOS page footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'iOS page footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'iOS page footer',
      },
      desktop: {
        src: footerImage,
        alt: 'iOS page footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
